/*******************************************************************************
 * Themes
 ******************************************************************************/
 
:root {
  --global-bg-color: #{$white-color};
  --global-text-color: #{$black-color};
  --global-theme-color: #{$purple-color};
  --global-hover-color: #{$light-purple-color};
  --global-footer-bg-color: #{$grey-color-dark};
  --global-footer-text-color: #{$grey-color-light};
  --global-footer-link-color: #{$white-color};
  --global-icon-color: #{$grey-color-dark};
  // --font-family-base: #{$font-family-base};
  // --font-family-sans-serif: 'Roboto';

  h1, h2, h3, h4, h5, h6, .navbar {
      font-family: #{$heading-font-family};
  }

  // strong {
  //     font-weight: bold;
  // }


  .fa-sun {
    display : none;
  }
  .fa-moon {
    padding-left: 10px;
    padding-top: 12px;
    display : block;
  }
}

html[data-theme='dark'] {
  --global-bg-color: #{$grey-color-dark};
  --global-text-color: #{$grey-color-light};
  --global-theme-color: #{$cyan-color};
  --global-hover-color: #{$light-cyan-color};
  --global-footer-bg-color: #{$grey-color-light};
  --global-footer-text-color: #{$grey-color-dark};
  --global-footer-link-color: #{$black-color};
  --global-icon-color: navajowhite;

  .fa-sun {
    padding-left: 10px;
    padding-top: 12px;
    display : block;
  }
  .fa-moon {
    display : none;
  }
}
