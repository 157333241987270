/*******************************************************************************
 * Variables used throughout the theme.
 * To adjust anything, simply edit the variables below and rebuild the theme.
 ******************************************************************************/


// Typography
$base-font-size: 16px !default;
$small-font-size:  0.875rem !default;
$bold-font-weight: bold !default;
$font-family-base: EBGaramond, Libre Baskerville, serif !default;
$line-height: 1.5 !default;
$heading-font-family: Montserrat, sans-serif !default;
$heading-font-weight: 600 !default;
$heading-line-height: 1.25 !default;
$monospace-font-family: 'Source Code Pro', Consolas, monospace !default;
$h1: 2.998rem !default;
$h2: 1.5rem !default;
$h3: 1.25rem !default;
$h4: 1rem !default;
$h5: .875rem !default;
$h6: .75rem !default;
$h00: 4rem !default;
$h0: 3rem !default;
$h00-responsive: 8vw !default;
$h0-responsive: 6vw !default;
$h1-responsive: 4vw !default;
$h00-responsive-max: 7.68rem !default;
$h0-responsive-max: 5.76rem !default;
$h1-responsive-max: 3.84rem !default;
$abbr-border-bottom: 1px black dotted;

// Page width
// $measure-width: 42rem !default;
$measure-width: 36rem !default;


// Viewport widths
$viewport-small: 32em;
$viewport-medium: 48em;
$viewport-large: 64em;

// Colors
$red-color:           #FF3636 !default;
$red-color-dark:      #B71C1C !default;
$orange-color:        #F29105 !default;
$blue-color:          #0076df !default;
$blue-color-dark:     #00369f !default;
$cyan-color:          #2698BA !default;
$light-cyan-color:    lighten($cyan-color, 25%);
$green-color:         #00ab37 !default;
$green-color-lime:    #B7D12A !default;
$green-color-dark:    #009f06 !default;
$green-color-light:   #ddffdd !default;
$green-color-bright:  #11D68B !default;
$purple-color:        #B509AC !default;
$light-purple-color:  lighten($purple-color, 25%);
$pink-color:          #f92080 !default;
$pink-color-light:    #ffdddd !default;
$yellow-color:        #efcc00 !default;

$grey-color:          #828282 !default;
$grey-color-light:    lighten($grey-color, 40%);
$grey-color-dark:     darken($grey-color, 25%);

$white-color: #ffffff !default;
$black-color: #000000 !default;
